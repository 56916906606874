import React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';



export default function DashboardPage(props) {
  return (    
    <div>
      <Card>
        <CardHeader title="Dashboard" />
        <Divider />
        <CardContent>
          <Typography variant="body2">
            Page Content
          </Typography>
        </CardContent>        
      </Card>
    </div>
  );
}