import React, { useState, useEffect, useLayoutEffect } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { fetchOurClients, updateOurClient, deleteOurClientImage } from '../store/actions/services';
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';



export default function EditOurClientPage(props) {

  const [values, setValues] = useState({
    Id: '',
    Name: '',
    Image: '',
    Status: '',
  });

  const [errorMsg, setErrorMsg] = useState(null);
  const [dialogOpen, setdialog] = useState(false);



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrorMsg(null);
  };

  const handlechangedropdown = (selected, state) => {
    setValues({ ...values, [state]: selected !== null ? selected.value : '' });
    setErrorMsg(null);
  }


  // eslint-disable-next-line
  // useLayoutEffect(mount, []);

  useEffect(() => {

    if (location.state && location.state.clientDetail) {
      setValues({
        Id: (location.state.clientDetail && location.state.clientDetail.Id) || "",
        Name: (location.state.clientDetail && location.state.clientDetail.Name) || "",
        Image: (location.state.clientDetail && location.state.clientDetail.Image) || "",
        Status: (location.state.clientDetail && location.state.clientDetail.Status) || "",
      });

    }
  }, [location]);

  const isInputValid = () => {
    let isInputValid = true;
    let errorMsg = "";

    if (values.Name === "") {
      isInputValid = false;
      errorMsg = "Please Enter Client Name.";
    } else if (values.Image === "") {
      isInputValid = false;
      errorMsg = "Please Select Client's Image.";
    }
    setErrorMsg(errorMsg);

    return isInputValid;
  }

  const hiddenFileInput = React.useRef(null);


  const handleUploadImgBtn = () => {
    //method to Link FileInput element
    hiddenFileInput.current.click();
  };

  const handleAddImg = (event) => {

    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setValues({ ...values, Image: e.target.result });
    }
  };

  const handleDeleteimg = (clientId) => {
    dispatch(deleteOurClientImage(clientId)).then(r => dispatch(fetchOurClients()));
    setValues({ ...values, Image: "" });
    setdialog(false);
  };

  const handleSubmit = () => {
    if (isInputValid()) {
      var UpdatedOurClientParams;
      if (document.getElementById("ClientImgFInput").files.length == 0) {
        UpdatedOurClientParams = {
          Id: values.Id,
          Name: values.Name,
          Status: values.Status
        };

        // console.log("client without image", UpdatedOurClientParams);

        dispatch(updateOurClient(UpdatedOurClientParams)).then(r => dispatch(fetchOurClients()));
        navigate("/ourClients");
      }
      else {
        UpdatedOurClientParams = {
          Id: values.Id,
          Name: values.Name,
          Image: values.Image,
          Status: values.Status
        };

        // console.log("client with image", UpdatedOurClientParams);


        dispatch(updateOurClient(UpdatedOurClientParams)).then(r => dispatch(fetchOurClients()));
        navigate("/ourClients");
      }

    }
  }







  const StatusArray = [
    { label: "Active", value: "Active" },
    { label: "In-Active", value: "InActive" },
  ];

  return (
    <div>
      <Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CardHeader title="Edit Our Client" />
          <div
            style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "auto", marginRight: 18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/ourClients") }}>Cancel</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
          <div>
            <Card>
              <CardContent sx={{ p: 5 }}>
                <Grid container spacing={3}>
                  <Grid container xs={12} mb={6} alignItems="center" justifyContent="center" flexDirection="column" >
                    <Typography variant="h5" >Client Image</Typography>
                    <Typography variant="subtitle1" >Image should be (512 x 512px)</Typography>

                    <div className='imageuploadbox'>

                      {(values.Image) && (
                        <div style={{ position: "relative", padding: 5, }}>
                          <IconButton
                            style={{ position: "absolute", right: 2, top: 2 }}
                            sx={{ backgroundColor: "white" }}
                            onClick={() => setdialog(true)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                          <img src={values.Image} width={210} height={125} style={{ alignSelf: "center", borderRadius: 15, objectFit: "contain" }} />
                        </div>
                      )}
                    </div>
                    {(!values.Image) && (
                      <Button style={{ marginTop: 14 }} variant='contained' color="primary" onClick={() => handleUploadImgBtn()}>
                        Upload Image
                      </Button>
                    )}
                    <input ref={hiddenFileInput} style={{ display: 'none' }} id="ClientImgFInput" className="choosefile-input" type="file" onChange={handleAddImg} />

                    <Dialog open={dialogOpen} onClose={() => setdialog(false)} >
                      <DialogTitle >
                        {"Delete Image"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText >
                          Are you sure you wish to delete this client Image ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleDeleteimg(values.Id)}>Yes</Button>
                        <Button onClick={() => setdialog(false)} autoFocus>
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>

                  </Grid>

                  <Grid item xs={6} >
                    <TextField fullWidth label="Client Name" value={values.Name} name="Name" onChange={handleChange} />
                  </Grid>


                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Status"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                        }}
                        value={StatusArray.filter(s => s.value === values.Status)}
                        // isLoading={true}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selected) => handlechangedropdown(selected, "Status")}
                        options={StatusArray}
                      />
                    </FormControl>
                  </Grid>

                </Grid>
              </CardContent>

              <Divider />

              <div style={{ textAlign: "center", padding: 16 }}>
                {(errorMsg && errorMsg !== "") && (
                  <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                )}
                <Button variant='contained' color='secondary' onClick={handleSubmit} >Update</Button>
              </div>
            </Card>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}