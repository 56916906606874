import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { fetchOurClients, deleteOurClient } from '../store/actions/services';


export default function OurClientsPage(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const [dialogOpen, setdialog] = useState(false);
    const [deleteOrderId, setdeleteOrderId] = useState("");

    const ourClientsList = useSelector(state => (state.services.ourClients || []));
    const isOurClientListFetching = useSelector(state => (state.services.isOurClientListFetching));

    const mount = () => {
        dispatch(fetchOurClients());

    }
    useEffect(mount, []);

    const handleEditClick = (id) => {
         var fltr_client = Object.assign({}, ...ourClientsList.filter(c => { if (c.Id === id) return c; }));
        navigate("/editOurClient", { state: { clientDetail: fltr_client } });
    };

    const handleDeleteClick = (id) => {
        dispatch(deleteOurClient(id)).then(r => dispatch(fetchOurClients()));
        setdialog(false);
    };

    /* const handleCellClick = (params) => {
      if(params.field !== "actions"){
        navigate("/viewQuotation", { state: { quotation: params.id }} );
      }
    };
   */

    const columns = [
        { field: 'id', headerName: 'Id', align: "left", flex: 0.3 },
        { field: 'image', headerName: 'Image', align: "left", flex: 0.5,
            renderCell: (params) => {
                return (
                    <div>
                        <img src={(params.row.image ? params.row.image : <GroupAddOutlinedIcon />)} alt='Client image' width="42" height="42" style={{ borderRadius: "50%", objectFit: "contain" }} />
                    </div>
                )
            }
        },
        { field: 'name', headerName: 'Name', align: "left", flex: 0.5 },
        { field: 'status', headerName: 'Status', align: "left", flex: 0.5 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <Tooltip title="Edit">
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => handleEditClick(id)}
                            color="inherit"
                        />
                    </Tooltip>,
                    <div>
                        <Tooltip title="Delete">
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={() => {
                                    setdeleteOrderId(id);
                                    setdialog(true);
                                }}
                                color="inherit"
                            />
                        </Tooltip>
                        <Dialog open={dialogOpen} style={{ opacity: "0.5" }} onClose={() => setdialog(false)} >
                            <DialogTitle >
                                {"Delete Client"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you wish to delete this Client ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleDeleteClick(deleteOrderId)}>Yes</Button>
                                <Button onClick={() => setdialog(false)} autoFocus>No</Button>
                            </DialogActions>
                        </Dialog>
                    </div>,
                ];
            }
        }
    ];

    const rows = ourClientsList.map(o => {
        return ({
            id: o.Id,
            image: o.Image,
            name: o.Name,
            status: (o.Status ? o.Status : "null"),
        })
    });

    return (
        <div>
            <Card>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <CardHeader title="Our Clients" />
                    <Button
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "auto",
                            marginRight: 18
                        }}
                        onClick={() => { navigate("/addOurClient") }}
                    >
                        <AddCircleIcon />
                        <Typography component={"span"} style={{ marginLeft: 4 }}>Add Client</Typography>
                    </Button>
                </div>

                <Divider />

                {ourClientsList && (
                    <CardContent>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            loading={isOurClientListFetching ? true : false}
                            autoHeight={18}
                            pageSize={pageSize}
                            // onCellClick={handleCellClick}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 20]}
                            pagination
                        />
                    </CardContent>
                )}

            </Card>
        </div>
    );
}