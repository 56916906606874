import { CALL_API } from '../api';

export const PRTYPES_REQUEST = "PRTYPES_REQUEST";
export const PRTYPES_SUCCESS = "PRTYPES_SUCCESS";
export const PRTYPES_FAILURE = "PRTYPES_FAILURE";

export const BRANDS_REQUEST = "BRANDS_REQUEST";
export const BRANDS_SUCCESS = "BRANDS_SUCCESS";
export const BRANDS_FAILURE = "BRANDS_FAILURE";

export const PRSIZES_REQUEST = "PRSIZES_REQUEST";
export const PRSIZES_SUCCESS = "PRSIZES_SUCCESS";
export const PRSIZES_FAILURE = "PRSIZES_FAILURE";

export const CAT_REQUEST = "CAT_REQUEST";
export const CAT_SUCCESS = "CAT_SUCCESS";
export const CAT_FAILURE = "CAT_FAILURE";

export const ADDCAT_REQUEST = "ADDCAT_REQUEST";
export const ADDCAT_SUCCESS = "ADDCAT_SUCCESS";
export const ADDCAT_FAILURE = "ADDCAT_FAILURE";

export const UCAT_REQUEST = "UCAT_REQUEST";
export const UCAT_SUCCESS = "UCAT_SUCCESS";
export const UCAT_FAILURE = "UCAT_FAILURE";

export const DELCATIMG_REQUEST = "DELCATIMG_REQUEST";
export const DELCATIMG_SUCCESS = "DELCATIMG_SUCCESS";
export const DELCATIMG_FAILURE = "DELCATIMG_FAILURE";

export const DELCAT_REQUEST = "DELCAT_REQUEST";
export const DELCAT_SUCCESS = "DELCAT_SUCCESS";
export const DELCAT_FAILURE = "DELCAT_FAILURE";

export const ADDBRAND_REQUEST = "ADDBRAND_REQUEST";
export const ADDBRAND_SUCCESS = "ADDBRAND_SUCCESS";
export const ADDBRAND_FAILURE = "ADDBRAND_FAILURE";

export const UBRAND_REQUEST = "UBRAND_REQUEST";
export const UBRAND_SUCCESS = "UBRAND_SUCCESS";
export const UBRAND_FAILURE = "UBRAND_FAILURE";

export const DELBRIMG_REQUEST = "DELBRIMG_REQUEST";
export const DELBRIMG_SUCCESS = "DELBRIMG_SUCCESS";
export const DELBRIMG_FAILURE = "DELBRIMG_FAILURE";

export const DELBRAND_REQUEST = "DELBRAND_REQUEST";
export const DELBRAND_SUCCESS = "DELBRAND_SUCCESS";
export const DELBRAND_FAILURE = "DELBRAND_FAILURE";

export const ADDPRDTTYPE_REQUEST = "ADDPRDTTYPE_REQUEST";
export const ADDPRDTTYPE_SUCCESS = "ADDPRDTTYPE_SUCCESS";
export const ADDPRDTTYPE_FAILURE = "ADDPRDTTYPE_FAILURE";

export const UPRDTTYPE_REQUEST = "UPRDTTYPE_REQUEST";
export const UPRDTTYPE_SUCCESS = "UPRDTTYPE_SUCCESS";
export const UPRDTTYPE_FAILURE = "UPRDTTYPE_FAILURE";

export const DELPRDTTYPEIMG_REQUEST = "DELPRDTTYPEIMG_REQUEST";
export const DELPRDTTYPEIMG_SUCCESS = "DELPRDTTYPEIMG_SUCCESS";
export const DELPRDTTYPEIMG_FAILURE = "DELPRDTTYPEIMG_FAILURE";

export const DELPRDTTYPE_REQUEST = "DELPRDTTYPE_REQUEST";
export const DELPRDTTYPE_SUCCESS = "DELPRDTTYPE_SUCCESS";
export const DELPRDTTYPE_FAILURE = "DELPRDTTYPE_FAILURE";

/* Our Clients */
export const OUR_CLIENT_LIST_REQUEST = "OUR_CLIENT_LIST_REQUEST";
export const OUR_CLIENT_LIST_SUCCESS = "OUR_CLIENT_LIST_SUCCESS";
export const OUR_CLIENT_LIST_FAILURE = "OUR_CLIENT_LIST_FAILURE";

export const OUR_CLIENT_DETAIL_REQUEST = "OUR_CLIENT_DETAIL_REQUEST";
export const OUR_CLIENT_DETAIL_SUCCESS = "OUR_CLIENT_DETAIL_SUCCESS";
export const OUR_CLIENT_DETAIL_FAILURE = "OUR_CLIENT_DETAIL_FAILURE";

export const ADD_OUR_CLIENT_REQUEST = "ADD_OUR_CLIENT_REQUEST";
export const ADD_OUR_CLIENT_SUCCESS = "ADD_OUR_CLIENT_SUCCESS";
export const ADD_OUR_CLIENT_FAILURE = "ADD_OUR_CLIENT_FAILURE";

export const UPDATE_OUR_CLIENT_REQUEST = "UPDATE_OUR_CLIENT_REQUEST";
export const UPDATE_OUR_CLIENT_SUCCESS = "UPDATE_OUR_CLIENT_SUCCESS";
export const UPDATE_OUR_CLIENT_FAILURE = "UPDATE_OUR_CLIENT_FAILURE";

export const DELETE_OUR_CLIENT_REQUEST = "DELETE_OUR_CLIENT_REQUEST";
export const DELETE_OUR_CLIENT_SUCCESS = "DELETE_OUR_CLIENT_SUCCESS";
export const DELETE_OUR_CLIENT_FAILURE = "DELETE_OUR_CLIENT_FAILURE";

export const DELETE_OUR_CLIENT_IMAGE_REQUEST = "DELETE_OUR_CLIENT_IMAGE_REQUEST";
export const DELETE_OUR_CLIENT_IMAGE_SUCCESS = "DELETE_OUR_CLIENT_IMAGE_SUCCESS";
export const DELETE_OUR_CLIENT_IMAGE_FAILURE = "DELETE_OUR_CLIENT_IMAGE_FAILURE";

/* Shop by Category */
export const SHOP_CATEGORY_LIST_REQUEST = "SHOP_CATEGORY_LIST_REQUEST";
export const SHOP_CATEGORY_LIST_SUCCESS = "SHOP_CATEGORY_LIST_SUCCESS";
export const SHOP_CATEGORY_LIST_FAILURE = "SHOP_CATEGORY_LIST_FAILURE";

export const ADD_SHOP_BY_CAT_REQUEST = "ADD_SHOP_BY_CAT_REQUEST";
export const ADD_SHOP_BY_CAT_SUCCESS = "ADD_SHOP_BY_CAT_SUCCESS";
export const ADD_SHOP_BY_CAT_FAILURE = "ADD_SHOP_BY_CAT_FAILURE";

export const UPDATE_SHOP_BY_CAT_REQUEST = "UPDATE_SHOP_BY_CAT_REQUEST";
export const UPDATE_SHOP_BY_CAT_SUCCESS = "UPDATE_SHOP_BY_CAT_SUCCESS";
export const UPDATE_SHOP_BY_CAT_FAILURE = "UPDATE_SHOP_BY_CAT_FAILURE";

export const DELETE_SHOP_BY_CAT_REQUEST = "DELETE_SHOP_BY_CAT_REQUEST";
export const DELETE_SHOP_BY_CAT_SUCCESS = "DELETE_SHOP_BY_CAT_SUCCESS";
export const DELETE_SHOP_BY_CAT_FAILURE = "DELETE_SHOP_BY_CAT_FAILURE";

export const DELETE_SHOP_BY_CAT_IMAGE_REQUEST = "DELETE_SHOP_BY_CAT_IMAGE_REQUEST";
export const DELETE_SHOP_BY_CAT_IMAGE_SUCCESS = "DELETE_SHOP_BY_CAT_IMAGE_SUCCESS";
export const DELETE_SHOP_BY_CAT_IMAGE_FAILURE = "DELETE_SHOP_BY_CAT_IMAGE_FAILURE";

/* shop by Brands */
export const SHOP_BRAND_LIST_REQUEST = "SHOP_BRAND_LIST_REQUEST";
export const SHOP_BRAND_LIST_SUCCESS = "SHOP_BRAND_LIST_SUCCESS";
export const SHOP_BRAND_LIST_FAILURE = "SHOP_BRAND_LIST_FAILURE";

export const ADD_SHOP_BY_BRAND_REQUEST = "ADD_SHOP_BY_BRAND_REQUEST";
export const ADD_SHOP_BY_BRAND_SUCCESS = "ADD_SHOP_BY_BRAND_SUCCESS";
export const ADD_SHOP_BY_BRAND_FAILURE = "ADD_SHOP_BY_BRAND_FAILURE";

export const UPDATE_SHOP_BY_BRAND_REQUEST = "UPDATE_SHOP_BY_BRAND_REQUEST";
export const UPDATE_SHOP_BY_BRAND_SUCCESS = "UPDATE_SHOP_BY_BRAND_SUCCESS";
export const UPDATE_SHOP_BY_BRAND_FAILURE = "UPDATE_SHOP_BY_BRAND_FAILURE";

export const DELETE_SHOP_BY_BRAND_REQUEST = "DELETE_SHOP_BY_BRAND_REQUEST";
export const DELETE_SHOP_BY_BRAND_SUCCESS = "DELETE_SHOP_BY_BRAND_SUCCESS";
export const DELETE_SHOP_BY_BRAND_FAILURE = "DELETE_SHOP_BY_BRAND_FAILURE";

export const DELETE_SHOP_BY_BRAND_IMAGE_REQUEST = "DELETE_SHOP_BY_BRAND_IMAGE_REQUEST";
export const DELETE_SHOP_BY_BRAND_IMAGE_SUCCESS = "DELETE_SHOP_BY_BRAND_IMAGE_SUCCESS";
export const DELETE_SHOP_BY_BRAND_IMAGE_FAILURE = "DELETE_SHOP_BY_BRAND_IMAGE_FAILURE";

/* Recommended Products */
export const RECOMMENDED_PROD_LIST_REQUEST = "RECOMMENDED_PROD_LIST_REQUEST";
export const RECOMMENDED_PROD_LIST_SUCCESS = "RECOMMENDED_PROD_LIST_SUCCESS";
export const RECOMMENDED_PROD_LIST_FAILURE = "RECOMMENDED_PROD_LIST_FAILURE";

export const ADD_RECOMMENDED_PROD_REQUEST = "ADD_RECOMMENDED_PROD_REQUEST";
export const ADD_RECOMMENDED_PROD_SUCCESS = "ADD_RECOMMENDED_PROD_SUCCESS";
export const ADD_RECOMMENDED_PROD_FAILURE = "ADD_RECOMMENDED_PROD_FAILURE";

// export const UPDATE_RECOMMENDED_PROD_REQUEST = "UPDATE_RECOMMENDED_PROD_REQUEST";
// export const UPDATE_RECOMMENDED_PROD_SUCCESS = "UPDATE_RECOMMENDED_PROD_SUCCESS";
// export const UPDATE_RECOMMENDED_PROD_FAILURE = "UPDATE_RECOMMENDED_PROD_FAILURE";

export const DELETE_RECOMMENDED_PROD_REQUEST = "DELETE_RECOMMENDED_PROD_REQUEST";
export const DELETE_RECOMMENDED_PROD_SUCCESS = "DELETE_RECOMMENDED_PROD_SUCCESS";
export const DELETE_RECOMMENDED_PROD_FAILURE = "DELETE_RECOMMENDED_PROD_FAILURE";

export const DELETE_RECOMMENDED_PROD_IMAGE_REQUEST = "DELETE_RECOMMENDED_PROD_IMAGE_REQUEST";
export const DELETE_RECOMMENDED_PROD_IMAGE_SUCCESS = "DELETE_RECOMMENDED_PROD_IMAGE_SUCCESS";
export const DELETE_RECOMMENDED_PROD_IMAGE_FAILURE = "DELETE_RECOMMENDED_PROD_IMAGE_FAILURE";


export const fetchProductTypes = () => ({
    [CALL_API]: {
      types: [PRTYPES_REQUEST, PRTYPES_SUCCESS, PRTYPES_FAILURE],
      method: 'GET',
      endpoint: 'product/getproducttypelist'
    }
});

export const fetchBrands = (id) => (dispatch, getState) => {
    var endpoint = 'brand/getbrandlist';
    if(id){
      endpoint = 'brand/getbrandlist/category/' + id;
    }
    return dispatch({
      [CALL_API]: {
        types: [BRANDS_REQUEST, BRANDS_SUCCESS, BRANDS_FAILURE],
        method: 'GET',
        endpoint: endpoint
      }
    });
};

export const fetchProductSizes = () => ({
  [CALL_API]: {
    types: [PRSIZES_REQUEST, PRSIZES_SUCCESS, PRSIZES_FAILURE],
    method: 'GET',
    endpoint: 'base/getproductsizelist'
  }
});

export const fetchCategories = () => ({
  [CALL_API]: {
    types: [CAT_REQUEST, CAT_SUCCESS, CAT_FAILURE],
    method: 'GET',
    endpoint: 'category/getservicecategorylist'
  }
});

export const addCategory = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [ADDCAT_REQUEST, ADDCAT_SUCCESS, ADDCAT_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'category/add'
    }
  });
};

export const updateCategory = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [UCAT_REQUEST, UCAT_SUCCESS, UCAT_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'category/update'
    }
  });
};

export const deleteCategoryImage = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [DELCATIMG_REQUEST, DELCATIMG_SUCCESS, DELCATIMG_FAILURE],
      method: 'GET',
      endpoint: 'category/deleteimage/' + id
    }
  });
};

export const deleteCategory = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [DELCAT_REQUEST, DELCAT_SUCCESS, DELCAT_FAILURE],
      method: 'GET',
      endpoint: 'category/delete/' + id
    }
  });
};

export const addBrand = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [ADDBRAND_REQUEST, ADDBRAND_SUCCESS, ADDBRAND_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'brand/add'
    }
  });
};

export const updateBrand = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [UBRAND_REQUEST, UBRAND_SUCCESS, UBRAND_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'brand/update'
    }
  });
};

export const deleteBrandImage = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [DELBRIMG_REQUEST, DELBRIMG_SUCCESS, DELBRIMG_FAILURE],
      method: 'GET',
      endpoint: 'brand/deleteimage/' + id
    }
  });
};

export const deleteBrand = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [DELBRAND_REQUEST, DELBRAND_SUCCESS, DELBRAND_FAILURE],
      method: 'GET',
      endpoint: 'brand/delete/' + id
    }
  });
};

export const addProductType = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [ADDPRDTTYPE_REQUEST, ADDPRDTTYPE_SUCCESS, ADDPRDTTYPE_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'product/addtype'
    }
  });
};

export const updateProductType = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [UPRDTTYPE_REQUEST, UPRDTTYPE_SUCCESS, UPRDTTYPE_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'product/updatetype'
    }
  });
};

export const deleteProductTypeImage = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [DELPRDTTYPEIMG_REQUEST, DELPRDTTYPEIMG_SUCCESS, DELPRDTTYPEIMG_FAILURE],
      method: 'GET',
      endpoint: 'product/deletetypeimage/' + id
    }
  });
};

export const deleteProductType = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [DELPRDTTYPE_REQUEST, DELPRDTTYPE_SUCCESS, DELPRDTTYPE_FAILURE],
      method: 'GET',
      endpoint: 'product/deletetype/' + id
    }
  });
};

/* our Clients */
export const fetchOurClients = () => ({
  [CALL_API]: {
    types: [OUR_CLIENT_LIST_REQUEST, OUR_CLIENT_LIST_SUCCESS, OUR_CLIENT_LIST_FAILURE],
    method: 'GET',
    endpoint: 'ourclients/getourclients'
  }
});

export const fetchOurClientDetail = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [OUR_CLIENT_DETAIL_REQUEST, OUR_CLIENT_DETAIL_SUCCESS, OUR_CLIENT_DETAIL_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'ourclients/getourclientdetails'
    }
  });
};

export const addOurClient = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [ADD_OUR_CLIENT_REQUEST, ADD_OUR_CLIENT_SUCCESS, ADD_OUR_CLIENT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'ourclients/add'
    }
  });
};

export const updateOurClient = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [UPDATE_OUR_CLIENT_REQUEST, UPDATE_OUR_CLIENT_SUCCESS, UPDATE_OUR_CLIENT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'ourclients/update'
    }
  });
};

export const deleteOurClient = (ourClientId) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELETE_OUR_CLIENT_REQUEST, DELETE_OUR_CLIENT_SUCCESS, DELETE_OUR_CLIENT_FAILURE],
        method: 'GET',
        endpoint: 'ourclients/delete/' + ourClientId
    }
  });
};

export const deleteOurClientImage = ( ourClientId) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELETE_OUR_CLIENT_IMAGE_REQUEST, DELETE_OUR_CLIENT_IMAGE_SUCCESS, DELETE_OUR_CLIENT_IMAGE_FAILURE],
        method: 'GET',
        endpoint: 'ourclients/deleteimage/'+ ourClientId 
    }
  });
};


/* Shop by Categories */
export const fetchShopBycategoryList = () => ({
  [CALL_API]: {
      types: [SHOP_CATEGORY_LIST_REQUEST, SHOP_CATEGORY_LIST_SUCCESS, SHOP_CATEGORY_LIST_FAILURE],
      method: 'GET',
      endpoint: 'shop/getshopcategory'
  }
});
export const addShopByCategory = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [ADD_SHOP_BY_CAT_REQUEST, ADD_SHOP_BY_CAT_SUCCESS, ADD_SHOP_BY_CAT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'shop/addshopcategory'
    }
  });
};
export const updateShopByCategory = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [UPDATE_SHOP_BY_CAT_REQUEST, UPDATE_SHOP_BY_CAT_SUCCESS, UPDATE_SHOP_BY_CAT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'shop/updateshopcategory'
    }
  });
};
export const deleteShopByCategory = (shopCatId) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELETE_SHOP_BY_CAT_REQUEST, DELETE_SHOP_BY_CAT_SUCCESS, DELETE_SHOP_BY_CAT_FAILURE],
        method: 'GET',
        endpoint: 'shop/deleteshopcategory/' + shopCatId  
    }
  });
};
export const deleteShopByCategoryImage = ( shopCatId) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELETE_SHOP_BY_CAT_IMAGE_REQUEST, DELETE_SHOP_BY_CAT_IMAGE_SUCCESS, DELETE_SHOP_BY_CAT_IMAGE_FAILURE],
        method: 'GET',
        endpoint: 'shop/deleteshopcategoryimage/'+ shopCatId 
    }
  });
};

/* Shop by Brands */
export const fetchShopByBrandsList = () => ({
  [CALL_API]: {
      types: [SHOP_BRAND_LIST_REQUEST, SHOP_BRAND_LIST_SUCCESS, SHOP_BRAND_LIST_FAILURE],
      method: 'GET',
      endpoint: 'shop/getshopbrand'
  }
});
export const addShopByBrand = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [ADD_SHOP_BY_BRAND_REQUEST, ADD_SHOP_BY_BRAND_SUCCESS, ADD_SHOP_BY_BRAND_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'shop/addshopbrand'
    }
  });
};
export const updateShopByBrand = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [UPDATE_SHOP_BY_BRAND_REQUEST, UPDATE_SHOP_BY_BRAND_SUCCESS, UPDATE_SHOP_BY_BRAND_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'shop/updateshopbrand'
    }
  });
};
export const deleteShopByBrand = (shopBrandId) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELETE_SHOP_BY_BRAND_REQUEST, DELETE_SHOP_BY_BRAND_SUCCESS, DELETE_SHOP_BY_BRAND_FAILURE],
        method: 'GET',
        endpoint: 'shop/deleteshopbrand/' + shopBrandId  
    }
  });
};
export const deleteShopByBrandImage = ( shopBrandId) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELETE_SHOP_BY_BRAND_IMAGE_REQUEST, DELETE_SHOP_BY_BRAND_IMAGE_SUCCESS, DELETE_SHOP_BY_BRAND_IMAGE_FAILURE],
        method: 'GET',
        endpoint: 'shop/deleteshopbrandimage/'+ shopBrandId 
    }
  });
};

/* Recommended Products */
  export const fetchRecommendedProdList = (body) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
          types: [RECOMMENDED_PROD_LIST_REQUEST, RECOMMENDED_PROD_LIST_SUCCESS, RECOMMENDED_PROD_LIST_FAILURE],
          method: 'POST',
          body: body,
          endpoint: 'productservice/getrecommendedproductlist'
      }
    });
};

export const addRecommendedProd = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [ADD_RECOMMENDED_PROD_REQUEST, ADD_RECOMMENDED_PROD_SUCCESS, ADD_RECOMMENDED_PROD_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'productservice/addrecommendedproduct'
    }
  });
};

/* export const updateRecommendedProd = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [UPDATE_RECOMMENDED_PROD_REQUEST, UPDATE_RECOMMENDED_PROD_SUCCESS, UPDATE_RECOMMENDED_PROD_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'ourclients/update'
    }
  });
}; */

export const deleteRecommendedProd = (recomProId) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELETE_RECOMMENDED_PROD_REQUEST, DELETE_RECOMMENDED_PROD_SUCCESS, DELETE_RECOMMENDED_PROD_FAILURE],
        method: 'GET',
        endpoint: 'productservice/deleterecommendedproduct/' + recomProId  
    }
  });
};
export const deleteRecommendedProdImage = ( recomProId) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELETE_RECOMMENDED_PROD_IMAGE_REQUEST, DELETE_RECOMMENDED_PROD_IMAGE_SUCCESS, DELETE_RECOMMENDED_PROD_IMAGE_FAILURE],
        method: 'GET',
        endpoint: 'productservice/deleterecommendedproductimage/'+ recomProId 
    }
  });
};
